<template>
  <Popup
    title="계약정보 변경"
    subTitle="계약정보를 변경하려면 상위승인이 필요합니다"
    :maxWidth="800"
    closeBtnText="취소"
    compeleteBtnText="변경 승인 요청하기"
    @onClickClose="$emit('onClickStoreContractModifyClose')"
    @onClickComplete="$emit('onClickStoreContractModifyComplete',modifyData)">
    <TableView>
      <!-- <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl"></strong>
        </div>
      </template> -->
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>상태</th>
          <td>
            <SwitchCheck
              :value.sync="is_active"
              text="활성"
              offText="비활성"
              @update:value="value => $store.dispatch('commonToast/fetchToastStart', `수정내용을 저장하여야 상태가 변경됩니다`)" />
            <p class="desc_info">💡 비활성 상태로 변경하면, 해당 가맹점의 결제기능이 차단됩니다.</p>
          </td>
        </tr>
        <tr>
          <th>정산계좌 은행<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Selectbox
              :dataList="'account_bank' | getSelectDataList"
              :value.sync="storeContractDataSync.bankName"
              maxWidth="300px" />
          </td>
        </tr>
        <tr>
          <th>정산계좌번호<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="정산계좌번호를 입력해주세요"
              valueType="accountNum"
              :value.sync="storeContractDataSync.bankAccount"
              maxWidth="300px"/>
          </td>
        </tr>
        <tr>
          <th>정산계좌 예금주<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="예금주 명을 입력해주세요"
              :value.sync="storeContractDataSync.bankAccountName"
              maxWidth="300px"/>
          </td>
        </tr>
        <tr>
          <th>수수로율<IconSvg iconName="essential" :size="8" iconColor="#F04848" /><span class="txt_info">(%)</span></th>
          <td>
            <Input
              placeholder="수수로율을 입력해주세요"
              valueType="numberCommaDot"
              :isBlockUnValueType="true"
              :value.sync="storeContractDataSync.fee"
              maxWidth="300px"/>
          </td>
        </tr>
        <tr>
          <th>정산주기<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Selectbox
              :dataList="'settle_cycle_type' | getSelectDataList"
              :value.sync="storeContractDataSync.settlementPeriod"
              maxWidth="300px" />
          </td>
        </tr>
        <!-- <tr>
          <th>가맹 신청서 첨부<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
          </td>
        </tr> -->
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';

export default {
  name:'StoreContractModify',
  components:{
    Popup,
    TableView,
    IconSvg,
    SwitchCheck,
    Selectbox,
    Input,
  },
  props:{
    storeContractData: Object,
  },
  mounted(){
    this.modifyData = this.storeContractData;
  },
  computed:{
    storeContractDataSync:{
      get(){
        return this.modifyData
      },
      set(val){
        this.modifyData = val
      }
    },
    is_active:{
      get(){
        return this.storeContractDataSync.status === 'REGISTERED'
      },
      set(val){
        return this.storeContractDataSync.status = val ? 'REGISTERED' : 'UNREGISTERED'
      }
    }
  },
  data(){
    return{
      modifyData:{}
    }
  }
}
</script>