import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils'
import { convertIdToText, getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'

export default class StoreModel {
  constructor(){
    this.objRaw = {};
    this.id = null;
    this.register_at = '';
    this.status = '';
    this.is_active = 'Y';
    this.store_code = '';
    this.companyType = '';
    this.taxClassification = '';
    this.name = '';
    this.businessRegistrationNumber = '';
    this.corporationNumber = '';
    this.presidentName = '';
    this.presidentPhoneNumber = '';
    this.email = '';
    this.is_agree_term = false;
    this.is_agree_marketing_sms = false;
    this.is_agree_marketing_email = false;
    this.store_online = false;
    this.store_offline = false;
    this.online_mall_url = '';
    this.online_mall_name = '';
    this.attach_corporate_file = '';
    this.manager_list = [];
    this.bankAccount = '';
    this.bankName = '';
    this.bankAccountName = '';
    this.fee = '';
    this.settlementPeriod = 'MONTH';
    this.attach_register_file = '';
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, register_at, status, is_active, store_code, companyType, taxClassification, name, businessRegistrationNumber, corporationNumber, presidentName, presidentPhoneNumber, email, is_agree_term, is_agree_marketing_sms, is_agree_marketing_email, store_online, store_offline, online_mall_url, online_mall_name, attach_corporate_file, bankAccount, bankName, bankAccountName, fee, settlementPeriod, attach_register_file } = obj;
    this.id = id;
    this.register_at = register_at;
    this.status = status;
    this.is_active = is_active;
    this.store_code = store_code;
    this.companyType = companyType;
    this.taxClassification = taxClassification;
    this.name = name;
    this.businessRegistrationNumber = businessRegistrationNumber;
    this.corporationNumber = corporationNumber;
    this.presidentName = presidentName;
    this.presidentPhoneNumber = presidentPhoneNumber;
    this.email = email;
    this.is_agree_term = is_agree_term;
    this.is_agree_marketing_sms = is_agree_marketing_sms;
    this.is_agree_marketing_email = is_agree_marketing_email;
    this.store_online = store_online;
    this.store_offline = store_offline;
    this.online_mall_url = online_mall_url;
    this.online_mall_name = online_mall_name;
    this.attach_corporate_file = attach_corporate_file;
    this.bankAccount = bankAccount;
    this.bankName = bankName;
    this.bankAccountName = bankAccountName;
    this.fee = fee;
    this.settlementPeriod = settlementPeriod;
    this.attach_register_file = attach_register_file;
  }
  setManagerList(data){
    this.manager_list = data;
  }
  getData(){
    let obj = {
      // id : this.id,
      // register_at : this.register_at,
      // is_active : this.is_active,
      // store_code : this.store_code,
      // companyType : this.companyType,
      // taxClassification : this.taxClassification,
      name : this.name,
      // businessRegistrationNumber : this.businessRegistrationNumber,
      presidentName : this.presidentName,
      presidentPhoneNumber : this.presidentPhoneNumber,
      email : this.email,
      // is_agree_term : this.is_agree_term,
      // is_agree_marketing_sms : this.is_agree_marketing_sms,
      // is_agree_marketing_email : this.is_agree_marketing_email,
      // store_online : this.store_online,
      // store_offline : this.store_offline,
      // online_mall_url : this.online_mall_url,
      // online_mall_name : this.online_mall_name,
      // attach_corporate_file : this.attach_corporate_file,
      // manager_list : this.manager_list,
      // settlementPeriod : this.settlementPeriod,
      // attach_register_file : this.attach_register_file
    }
    return obj;
  }
  // 기본정보
  getBasicInfoData(){
    let obj = {
      companyType : this.companyType,
      taxClassification : this.taxClassification,
      name : this.name,
      businessRegistrationNumber : this.businessRegistrationNumber,
      corporationNumber : this.corporationNumber,
      presidentName : this.presidentName,
      presidentPhoneNumber : this.presidentPhoneNumber,
      email : this.email,
    }
    return obj;
  }
  setBasicInfoData(obj){
    const { companyType, taxClassification, name, businessRegistrationNumber, corporationNumber, presidentName, presidentPhoneNumber, email } = obj;
    this.companyType = companyType;
    this.taxClassification = taxClassification;
    this.name = name;
    this.businessRegistrationNumber = businessRegistrationNumber;
    this.corporationNumber = corporationNumber;
    this.presidentName = presidentName;
    this.presidentPhoneNumber = presidentPhoneNumber;
    this.email = email;
  }
  // 담당자 정보
  getManagerData(){
    return this.manager_list;
  }
  // 계약정보
  getContractData(){
    let obj = {
      status : this.status,
      bankAccount : this.bankAccount,
      bankName : this.bankName,
      bankAccountName : this.bankAccountName,
      settlementPeriod : this.settlementPeriod,
      fee : this.fee,
    }
    return obj;
  }
  setContractData(){
    const { status, bankAccount, bankName, bankAccountName, fee } = obj;
    this.status = status;
    this.bankAccount = bankAccount;
    this.bankName = bankName;
    this.bankAccountName = bankAccountName;
    this.settlementPeriod = settlementPeriod;
    this.fee = fee;
  }
}