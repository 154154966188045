<template>
  <Popup
    title="계약정보 변경"
    :maxWidth="800"
    closeBtnText="취소"
    compeleteBtnText="저장하기"
    @onClickClose="$emit('onClickStoreBasicInfoModifyClose')"
    @onClickComplete="$emit('onClickStoreBasicInfoModifyComplete',modifyData)">
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">기본정보</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>회사구분<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Radio
              :dataList="'store_company_type' | getSelectDataList"
              :value.sync="storeBasicInfoDataSync.companyType"/>
          </td>
        </tr>
        <tr>
          <th>과세구분<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Radio
              :dataList="'store_tax_business_type' | getSelectDataList"
              :value.sync="storeBasicInfoDataSync.taxClassification"/>
          </td>
        </tr>
        <tr>
          <th>법인명/상호<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="법인명/상호를 입력해주세요"
              :value.sync="storeBasicInfoDataSync.name"/>
          </td>
        </tr>
        <tr>
          <th>사업자등록번호<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="사업자등록번호를 입력해주세요"
              :value.sync="storeBasicInfoDataSync.businessRegistrationNumber"/>
          </td>
        </tr>
        <tr v-if="storeBasicInfoDataSync.company_type != 'personal'">
          <th>법인등록번호<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="법인등록번호를 입력해주세요"
              :value.sync="storeBasicInfoDataSync.corporationNumber"/>
          </td>
        </tr>
        <tr>
          <th>대표자 이름<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="대표자 이름을 입력해주세요"
              :value.sync="storeBasicInfoDataSync.presidentName"/>
          </td>
        </tr>
        <tr>
          <th>대표자 휴대폰번호<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="대표자 휴대폰번호를 입력해주세요"
              valueType="tel"
              :value.sync="storeBasicInfoDataSync.presidentPhoneNumber"/>
            <p class="desc_info">💡 가맹점 시스템에 로그인시 해당 연락처로 보안 코드가 발송됩니다</p>
          </td>
        </tr>
        <tr>
          <th>대표 이메일<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <Input
              placeholder="대표 이메일을 입력해주세요"
              valueType="email"
              :value.sync="storeBasicInfoDataSync.email"/>
            <p class="desc_info">💡 가맹점 시스템의 아이디 입니다</p>
          </td>
        </tr>
        <!-- <tr>
          <th>개인정보수집동의<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <CheckboxOnlyOne
              id="checkboxOnlyOne"
              :value.sync="storeBasicInfoDataSync.is_agree_term"
              text="동의"/>
          </td>
        </tr>
        <tr>
          <th>마케팅 수신동의</th>
          <td>
            <CheckboxOnlyOne
              id="checkboxOnlyOne"
              :value.sync="storeBasicInfoDataSync.is_agree_marketing_sms"
              text="SMS"/>
            <CheckboxOnlyOne
              id="checkboxOnlyOne"
              :value.sync="storeBasicInfoDataSync.is_agree_marketing_email"
              text="e-mail"/>
          </td>
        </tr>
        <tr>
          <th>상점 온/오프라인 구분<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
            <CheckboxOnlyOne
              id="checkboxOnlyOne"
              :value.sync="storeBasicInfoDataSync.store_online"
              text="온라인"/>
            <CheckboxOnlyOne
              id="checkboxOnlyOne"
              :value.sync="storeBasicInfoDataSync.store_offline"
              text="오프라인"/>
          </td>
        </tr>
        <tr>
          <th>온라인몰 주소</th>
          <td>
            <Input
              placeholder="온라인 몰 운영시 기재"
              :value.sync="storeBasicInfoDataSync.online_mall_url"/>
          </td>
        </tr>
        <tr>
          <th>온라인몰 이름</th>
          <td>
            <Input
              placeholder="온라인 몰 운영시 기재"
              :value.sync="storeBasicInfoDataSync.online_mall_name"/>
          </td>
        </tr>
        <tr>
          <th>사업자 등록증 첨부<IconSvg iconName="essential" :size="8" iconColor="#F04848" /></th>
          <td>
          </td>
        </tr> -->
      </template>
    </TableView>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';

export default {
  name:'StoreBasicInfoModify',
  components:{
    Popup,
    TableView,
    IconSvg,
    Radio,
    Input,
  },
  props:{
    storeBasicInfoData: Object,
  },
  mounted(){
    this.modifyData = this.storeBasicInfoData;
  },
  computed:{
    storeBasicInfoDataSync:{
      get(){
        return this.modifyData
      },
      set(val){
        this.modifyData = val
      }
    },
  },
  data(){
    return{
      modifyData:{}
    }
  }
}
</script>