
export default class StoreManagerModel {
  constructor(){
    this.objRaw = {};
    this.affiliateId = '';
    this.uid = '';
    this.type = '';
    this.name = '';
    this.phone_number = '';
    this.email = '';
    this.image = '';
    this.isAgreeTerm = false;
    this.createdAt = '';
  }
  setDataList(array){
    if(array.length > 0){
      return array.map((item,index) => {
        return this.itemSetData(item,index);
      })
    }else{
      return []
    }
  }
  resetData(){
    this.affiliateId = '';
    this.uid = '';
    this.type = '';
    this.name = '';
    this.phone_number = '';
    this.email = '';
    this.image = '';
    this.isAgreeTerm = false;
    this.createdAt = '';
  }
  itemSetData(obj,index){
    const { affiliateId, uid, type, name, phone_number, email, image, isAgreeTerm, createdAt } = obj;
    let robj = {
      index : index+1,
      affiliateId : affiliateId,
      uid : uid,
      type : type || 'ORDER_MANAGER',
      name : name,
      phone_number : phone_number || '',
      email : email,
      image : image,
      isAgreeTerm : isAgreeTerm || true,
      createdAt : createdAt,
    }
    return robj;
  }
  getDataList(array){
    if(array.length > 0){
      return array.map((item,index) => {
        return this.itemGetData(item,index);
      })
    }else{
      return []
    }
  }
  itemGetData(obj,index){
    const { name, email, image } = obj;
    let robj = {
      index : index+1,
      name : name,
      email : email,
      image : image,
      valid : true
    }
    return robj;
  }
  getData(){
    let obj = {
      type : this.type,
      name : this.name,
      phone_number : this.phone_number,
      email : this.email,
      image : this.image,
      isAgreeTerm : this.isAgreeTerm,
      valid : true
    }
    return obj;
  }
}