import StoreModel from '@/views/store/store/model/StoreModel';
import StoreManagerModel from '@/views/store/store/model/StoreManagerModel';
import router from '@/router';
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import apiPath from '@/services/apiPath';

export default class StoreDetailViewModel {
  constructor() {
    this.categoryDataList = [];
    this.model = new StoreModel();
    this.managerModel = new StoreManagerModel();
    this.isModify = false;
    this.isStoreBasicInfoModifyPopup = false;
    this.isStoreContractModifyPopup = false;
    this.isStoreManagerModifyPopup = false;
    this.managerboardData = {
      title:'담당자 정보',
      drawDataList: [
        {
          title:'No.',
          width:'50px',
          value:'index',
        },
        // {
        //   title:'구분',
        //   width:'132px',
        //   value:'type',
        //   isAlignLeft: true,
        //   emptyValueText:'-',
        //   filter:{
        //     name:'convertIdToText',
        //     value:'store_manager_type'
        //   },
        // },
        {
          title:'이름',
          width:'110px',
          value:'name',
          emptyValueText:'-',
        },
        // {
        //   title:'전화번호',
        //   width:'150px',
        //   value:'phone_number',
        //   emptyValueText:'-',
        // },
        {
          title:'이메일주소',
          width:'',
          value:'email',
          emptyValueText:'-',
        },
        // {
        //   title:'개인정보수집동의',
        //   width:'150px',
        //   value:'isAgreeTerm',
        //   valueCustom:[
        //     { 
        //       if: {
        //         value:'isAgreeTerm',
        //         condition:true
        //       },
        //       type: 'text',
        //       value: '동의'
        //     },
        //     { 
        //       if: {
        //         value:'isAgreeTerm',
        //         condition:false
        //       },
        //       type: 'text',
        //       value: '미동의'
        //     },
        //   ],
        // },
      ],
      option:{
        isTotal: true,
        isSize: false,
        rowIdValue: 'index'
      },
      emptyDesc:'조회결과가 없습니다',
      paginationData:{
        totalCount:0,
        totalPage:0
      }
    };
  }
  init(id){
    if(id){
      this.getStoreDetail(id);
      this.getStoreManagerList(id);
    }else{
      this.addManager();
    }
  }
  addManager(){
    const managerModel = new StoreManagerModel();
    const newManagerItem = managerModel.getData();
    this.model.manager_list.push(newManagerItem)
  }
  onClickAddManager(){
    this.addManager();
  }
  onClickDeleteManager(deleteIndex){
    this.model.manager_list.splice(deleteIndex, 1);
  }
  onClickComplete(){
    store.dispatch('commonToast/fetchToastStart', `가맹점이 등록되었습니다`);
    router.push({ name: 'STORE_STORE_LIST' });
  }
  onClickModify(){
    const modifyOk = () => {
      store.dispatch('commonToast/fetchToastStart', `가맹점 정보가 수정되었습니다`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>가맹점 정보를 수정할까요?</strong><ul><li>수정 요청자 : 박하영</li><li>수수료율이나 정산계좌 등 정산정보는 계약 및 회계와 직결된 민감한 부분이오니 수정시 신중하게 확인해 주세요.</li><li>대표 이메일과 대표 휴대전화번호는 가맹점 시스템의 로그인 수단이오니 수정시 오탈자 등을 신중하게 확인해 주세요.</li></ul>`,
      closeBtnText: '취소',
      compeleteBtnText: '수정',
      confirmCallBack: modifyOk,
    });
  }
  // 기본정보 편집 팝업
  onClickStoreBasicInfoModify(){
    this.isStoreBasicInfoModifyPopup = true;
  }
  onClickStoreBasicInfoModifyClose(){
    this.isStoreBasicInfoModifyPopup = false;
  }
  onClickStoreBasicInfoModifyComplete(modifyData){
    this.model.setBasicInfoData(modifyData)
    this.isStoreBasicInfoModifyPopup = false;
  }
  // 담당자정보 편집 팝업
  onClickStoreManagerModify(){
    this.isStoreManagerModifyPopup = true;
  }
  onClickStoreManagerModifyClose(){
    this.isStoreManagerModifyPopup = false;
  }
  onClickStoreManagerModifyComplete(modifyData){
    this.model.setManagerList(this.managerModel.getDataList(modifyData));
    this.isStoreManagerModifyPopup = false;
  }
  // 계약정보 편집 팝업
  onClickStoreContractModify(){
    this.isStoreContractModifyPopup = true;
  }
  onClickStoreContractModifyClose(){
    this.isStoreContractModifyPopup = false;
  }
  onClickStoreContractModifyComplete(modifyData){
    this.postStoreContract(modifyData)
  }
  getStoreDetail(id){
    // this.model.setData(resultData);
    const path = `${apiPath.SOTRE_DETAIL.format(id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getStoreManagerList(id){
    const path = `${apiPath.SOTRE_MANAGER_LIST.format(id)}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.managerboardData.paginationData.totalCount = resultData.length;
      if(resultData.length === 0){
        this.addManager();
      }else{
        this.model.setManagerList(this.managerModel.setDataList(resultData));
      }
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  postStoreContract(data){
    const path = `${apiPath.STORE_CONTRACT_DRAFT.format(this.model.id)}`;
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      console.log(resultData);
      this.isStoreContractModifyPopup = false;
      store.dispatch('commonToast/fetchToastStart', '계약정보 변경요청이 완료되었습니다');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}