<template>
  <PageWithLayout>
    <TableView v-if="viewModel.isModify">
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">가맹정보</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>상태</th>
          <td>
            <SwitchCheck
              :value.sync="is_active"
              text="활성"
              offText="비활성"
              :isDisabled="true"
              @update:value="value => $store.dispatch('commonToast/fetchToastStart', `수정내용을 저장하여야 상태가 변경됩니다`)" />
            <p class="desc_info">💡 비활성 상태로 변경하면, 해당 가맹점의 결제기능이 차단됩니다.</p>
          </td>
        </tr>
        <!-- <tr>
          <th>등록일</th>
          <td>{{ viewModel.model.register_at | convertDateFormat('YYYY-MM-DD HH:mm') }}</td>
        </tr>
        <tr>
          <th>가맹코드</th>
          <td>{{ viewModel.model.store_code }}</td>
        </tr> -->
        <tr>
          <th>로그인 이메일<span class="txt_info">(대표 이메일과 동일)</span></th>
          <td>{{ viewModel.model.email }}</td>
        </tr>
        <!-- <tr>
          <th>로그인 비밀번호 초기화</th>
          <td>
            <Button
              v-if="viewModel.isModify"
              btnSize="small"
              btnStyle="secondary_border"
              text="임시 비밀번호 전송"/>
          </td>
        </tr> -->
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">기본정보</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary"
            text="편집하기"
            @onClickBtn="viewModel.onClickStoreBasicInfoModify()"/>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>회사구분</th>
          <td>
            {{ viewModel.model.companyType | convertIdToText('store_company_type') }}
          </td>
        </tr>
        <tr>
          <th>과세구분</th>
          <td>
            {{ viewModel.model.taxClassification | convertIdToText('store_tax_business_type') }}
          </td>
        </tr>
        <tr>
          <th>법인명/상호</th>
          <td>
            {{ viewModel.model.name }}
          </td>
        </tr>
        <tr>
          <th>사업자등록번호</th>
          <td>
            {{ viewModel.model.businessRegistrationNumber }}
          </td>
        </tr>
        <tr v-if="viewModel.model.company_type != 'personal'">
          <th>법인등록번호</th>
          <td>
            {{ viewModel.model.corporationNumber }}
          </td>
        </tr>
        <tr>
          <th>대표자 이름</th>
          <td>
            {{ viewModel.model.presidentName }}
          </td>
        </tr>
        <tr>
          <th>대표자 휴대폰번호</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.presidentPhoneNumber }}</span>
            <p class="desc_info">💡 가맹점 시스템에 로그인시 해당 연락처로 보안 코드가 발송됩니다</p>
          </td>
        </tr>
        <tr>
          <th>대표 이메일</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.email }}</span>
            <p class="desc_info">💡 가맹점 시스템의 아이디 입니다</p>
          </td>
        </tr>
        <!-- <tr>
          <th>개인정보수집동의</th>
          <td>
            <CheckboxOnlyOne
              id="checkboxOnlyOne"
              :value.sync="viewModel.model.is_agree_term"
              text="동의"/>
          </td>
        </tr>
        <tr>
          <th>마케팅 수신동의</th>
          <td>
            <CheckboxOnlyOne
              id="checkboxOnlyOne"
              :value.sync="viewModel.model.is_agree_marketing_sms"
              text="SMS"/>
            <CheckboxOnlyOne
              id="checkboxOnlyOne"
              :value.sync="viewModel.model.is_agree_marketing_email"
              text="e-mail"/>
          </td>
        </tr>
        <tr>
          <th>상점 온/오프라인 구분</th>
          <td>
            <CheckboxOnlyOne
              id="checkboxOnlyOne"
              :value.sync="viewModel.model.store_online"
              text="온라인"/>
            <CheckboxOnlyOne
              id="checkboxOnlyOne"
              :value.sync="viewModel.model.store_offline"
              text="오프라인"/>
          </td>
        </tr>
        <tr>
          <th>온라인몰 주소</th>
          <td>
            <Input
              placeholder="온라인 몰 운영시 기재"
              :value.sync="viewModel.model.online_mall_url"/>
          </td>
        </tr>
        <tr>
          <th>온라인몰 이름</th>
          <td>
            <Input
              placeholder="온라인 몰 운영시 기재"
              :value.sync="viewModel.model.online_mall_name"/>
          </td>
        </tr>
        <tr>
          <th>사업자 등록증 첨부</th>
          <td>
          </td>
        </tr> -->
      </template>
    </TableView>
    <Board
      :boardData="viewModel.managerboardData"
      :dataList="viewModel.model.manager_list"
      :paginationData="viewModel.managerboardData.paginationData"
      :emptyDesc="viewModel.managerboardData.emptyDesc">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="secondary"
          text="담당자 편집"
          @onClickBtn="viewModel.onClickStoreManagerModify()"/>
      </template>
    </Board>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">계약정보</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary"
            text="편집하기"
            @onClickBtn="viewModel.onClickStoreContractModify()"/>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>정산계좌 은행</th>
          <td>
            {{ viewModel.model.bankName | convertIdToText('account_bank') }}
          </td>
        </tr>
        <tr>
          <th>정산계좌번호</th>
          <td>
            {{ viewModel.model.bankAccount }}
          </td>
        </tr>
        <tr>
          <th>정산계좌 예금주</th>
          <td>
            {{ viewModel.model.bankAccountName }}
          </td>
        </tr>
        <tr>
          <th>수수로율</th>
          <td>
            {{ viewModel.model.fee }}%
          </td>
        </tr>
        <tr>
          <th>정산주기</th>
          <td>
            {{ viewModel.model.settlementPeriod | convertIdToText('settle_cycle_type') }}
          </td>
        </tr>
        <!-- <tr>
          <th>가맹 신청서 첨부</th>
          <td>
          </td>
        </tr> -->
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="STORE_STORE_LIST"/>
      </div>
      <div class="area_right">
        <!-- <Button
          v-if="viewModel.isModify"
          btnSize="medium"
          btnStyle="primary"
          text="수정내용 저장"
          @onClickBtn="viewModel.onClickModify()"/> -->
        <Button
          v-if="!viewModel.isModify"
          btnSize="medium"
          btnStyle="primary"
          text="등록"
          @onClickBtn="viewModel.onClickComplete()"/>
      </div>
    </Sticky>
    <template v-slot:popup>
      <StoreBasicInfoModify
        v-if="viewModel.isStoreBasicInfoModifyPopup"
        :storeBasicInfoData="viewModel.model.getBasicInfoData()"
        @onClickStoreBasicInfoModifyClose="viewModel.onClickStoreBasicInfoModifyClose()"
        @onClickStoreBasicInfoModifyComplete="modifyData => viewModel.onClickStoreBasicInfoModifyComplete(modifyData)"/>
      <StoreManagerModify
        v-if="viewModel.isStoreManagerModifyPopup"
        :storeManagerAddData="viewModel.managerModel.getData()"
        :storeManagerData="viewModel.model.getManagerData()"
        @onClickStoreManagerModifyClose="viewModel.onClickStoreManagerModifyClose()"
        @onClickStoreManagerModifyComplete="modifyData => viewModel.onClickStoreManagerModifyComplete(modifyData)"/>
      <StoreContractModify
        v-if="viewModel.isStoreContractModifyPopup"
        :storeContractData="viewModel.model.getContractData()"
        @onClickStoreContractModifyClose="viewModel.onClickStoreContractModifyClose()"
        @onClickStoreContractModifyComplete="modifyData => viewModel.onClickStoreContractModifyComplete(modifyData)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import CheckboxOnlyOne from '@lemontree-ai/lemontree-admin-common-front/components/common/checkbox/CheckboxOnlyOne';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// popup
import StoreBasicInfoModify from '@/views/store/store/view/popup/StoreBasicInfoModify';
import StoreManagerModify from '@/views/store/store/view/popup/StoreManagerModify';
import StoreContractModify from '@/views/store/store/view/popup/StoreContractModify';
// viewModel
import StoreDetailViewModel from '@/views/store/store/viewModel/StoreDetailViewModel'

export default {
  name:'StoreRegister',
  components:{
    PageWithLayout,
    TableView,
    Board,
    IconSvg,
    Radio,
    CheckboxOnlyOne,
    Selectbox,
    Input,
    SwitchCheck,
    Sticky,
    Button,

    StoreBasicInfoModify,
    StoreManagerModify,
    StoreContractModify,
  },
  beforeMount(){
    const id = this.$route.params.id;
    this.viewModel.init(id);
    this.viewModel.isModify = this.$route.meta.isModify;
  },
  data(){
    return{
      viewModel: new StoreDetailViewModel(),
    }
  },
  computed:{
    is_active:{
      get(){
        return this.viewModel.model.status != 'REGISTERED'
      },
      set(val){
        return this.viewModel.model.status = val ? 'REGISTERED' : 'UNREGISTERED'
      }
    },
  }
}
</script>