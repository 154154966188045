<template>
  <Popup
    title="담당자정보 변경"
    :maxWidth="800"
    closeBtnText="취소"
    compeleteBtnText="저장하기"
    :isDisabeldCompeleteBtn="vaildResult"
    @onClickClose="$emit('onClickStoreManagerModifyClose')"
    @onClickComplete="$emit('onClickStoreManagerModifyComplete',modifyData)">
    <TableList>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">담당자</strong>
          <p class="desc_tbl">({{ storeManagerDataSync.length }})</p>
        </div>
        <!-- <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary"
            text="추가하기"/>
        </div> -->
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:50px"/>
        <!-- <col style="width:132px"/> -->
        <col style="width:200px"/>
        <!-- <col style="width:150px"/> -->
        <col />
        <!-- <col style="width:110px"/> -->
        <col style="width:72px"/>
      </template>
      <template v-slot:tbl_head>
        <th>No.</th>
        <!-- <th>구분</th> -->
        <th>이름</th>
        <!-- <th>전화번호</th> -->
        <th>이메일주소</th>
        <!-- <th>개인정보수집동의</th> -->
        <th>삭제</th>
      </template>
      <template v-slot:tbl_body>
        <tbody>
          <tr
            v-for="(managerItem,index) in storeManagerDataSync"
            :key="`manager${index}_tr`">
            <td>
              {{ managerItem.index }}
            </td>
            <!-- <td>
              <Selectbox
                :dataList="'store_manager_type' | getSelectDataList"
                :value.sync="managerItem.type"/>
            </td> -->
            <td class="align_bottom">
              <Input
                placeholder="이름"
                :value.sync="managerItem.name"
                :valueType="vaildDataList[index].name.valueType"
                :isError.sync="vaildDataList[index].name.isValid"
                :errorDesc="vaildDataList[index].name.validAlertDesc"/>
            </td>
            <!-- <td>
              <Input
                placeholder="전화번호"
                :value.sync="managerItem.phone_number"
                :isBlockUnValueType="true"
                valueType="tel"/>
            </td> -->
            <td class="align_bottom">
              <Input
                placeholder="이메일주소"
                :value.sync="managerItem.email"
                :valueType="vaildDataList[index].email.valueType"
                :isError.sync="vaildDataList[index].email.isValid"
                :errorDesc="vaildDataList[index].email.validAlertDesc"
                :isBlockUnValueType="true"/>
            </td>
            <!-- <td>
              <CheckboxOnlyOne
                id="checkboxOnlyOne"
                text="동의"
                :value.sync="managerItem.isAgreeTerm"/>
            </td> -->
            <td class="align_bottom">
              <Button
                v-if="index > 0 || storeManagerDataSync.length > 1"
                btnSize="small"
                btnStyle="fourthly_border"
                text="삭제"
                @onClickBtn="onClickDeleteManager(index)"/>
            </td>
          </tr>
        </tbody>
      </template>
    </TableList>
    <template v-slot:footBtn>
      <Button
        btnSize="small"
        btnStyle="secondary"
        text="추가하기"
        @onClickBtn="onClickAddManager()"/>
    </template>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableList from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableList';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import CheckboxOnlyOne from '@lemontree-ai/lemontree-admin-common-front/components/common/checkbox/CheckboxOnlyOne';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'

export default {
  name:'StoreManagerModify',
  components:{
    Popup,
    TableList,
    IconSvg,
    Selectbox,
    Input,
    CheckboxOnlyOne,
    Button,
  },
  props:{
    storeManagerAddData:Object,
    storeManagerData: Array,
  },
  mounted(){
    this.setAddData();
    this.modifyData = this.modifyData.concat(this.storeManagerData);
    this.setVaildDataList();
  },
  computed:{
    storeManagerDataSync:{
      get(){
        return this.modifyData
      },
      set(val){
        this.modifyData = val
      }
    },
    is_active:{
      get(){
        return this.storeManagerDataSync.status === 'REGISTERED'
      },
      set(val){
        return this.storeManagerDataSync.status = val ? 'REGISTERED' : 'UNREGISTERED'
      }
    },
    vaildResult(){
      let result = false;
      this.vaildDataList.forEach((item,index) => {
        if( pageValidated(item, this.storeManagerDataSync[index]) ){
          result = true;
        }
      });
      return result;
    }
  },
  data(){
    return{
      addData:{},
      modifyData:[],
      vaildDataList:[],
      vaild:{
        name:{
          index: null,
          value: 'name',
          valueType:'',
          isValid:false,
          validAlertDesc: '한글,영문으로만 입력해주세요',
        },
        email:{
          index: null,
          value: 'email',
          valueType:'email',
          isValid:false,
          validAlertDesc: 'E-mail 양식을 확인해주세요',
        },
      }
    }
  },
  methods:{
    setAddData(){
      this.addData = Object.assign({},this.storeManagerAddData);
    },
    setVaildDataList(){
      for(let item of this.storeManagerData){
        this.setVaildDataItem(item.index);
      }
    },
    setVaildDataItem(index){
      const {name, email} = this.vaild;
      let vaild = {};
      vaild.name = Object.assign({},name);
      vaild.name.index = index;
      vaild.email = Object.assign({},email);
      vaild.email.index = index;
      this.vaildDataList.push(vaild);
    },
    onClickDeleteManager(deleteIndex){
      this.storeManagerDataSync.splice(deleteIndex, 1);
      this.vaildDataList.splice(deleteIndex, 1);
    },
    onClickAddManager(){
      const index = this.storeManagerDataSync.length+1;
      this.addData.index = index;
      this.storeManagerDataSync.push(this.addData);
      this.setAddData();
      this.setVaildDataItem(index);
    }
  }
}
</script>
<style scoped>
.tbl_list .check_comm{margin-right:0}
</style>